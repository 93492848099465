<template>
  <div class="home">
    <Header></Header>


<div class="container_1200">
      <div class="home_hero_content">
      <div class="home_hero_square-yellow">
        <h1 class="oswald_bold_blue_35 margin_bottom_30">Maniobras y transportes, grúas y plataformas</h1>
        <p class="open_regular_blue_18 margin_bottom_30"><span>¿No encuentras el servicio?</span> Contáctanos. Te orientamos técnicamente.
        </p>
        <a class="button_generic_style button_blue-principal_blue-black height_40" href="/servicios">Conocer más servicios</a>
      </div>
      <div class="home_hero_squares">
        <div class="home_hero_square-blue margin_bottom_30 square_blue_1">
          <img loading="lazy" class="image_gruas_trans" src="@/assets/image/gruas-servicios.webp" alt="">
          <h2 class="oswald_bold_white_24">Grúas hidráulicas</h2>
        </div>
        <div class="home_hero_square-blue margin_bottom_30 square_blue_2">
          <img loading="lazy" class="image_plataformas_trans" src="@/assets/image/maniobras-image.webp" alt="">
          <h2 class="oswald_bold_white_24">Maniobras</h2>
        </div>
        <div class="block_break_sm"></div>
        <div class="home_hero_square-blue margin_bottom_30 square_blue_3">
          <img loading="lazy" class="image_tractocamiones_trans" src="@/assets/image/tractocamiones-servicios.webp" alt="">
          <h2 class="oswald_bold_white_24">Tractocamiones</h2>
        </div>
        <div class="block_break"></div>
        <div class="home_hero_square-blue square_blue_4">
          <img loading="lazy" class="image_monta_trans" src="@/assets/image/montacargas-servicios.webp" alt="">
          <h2 class="oswald_bold_white_24">Montacargas</h2>
        </div>
        <div class="block_break_sm"></div>
        <div class="home_hero_square-blue square_blue_5">
          <img loading="lazy" class="image_lowboy_trans" src="@/assets/image/lowboy-servicios.webp" alt="">
          <h2 class="oswald_bold_white_24">Low boy</h2>
        </div>
        <div class="block_break_xs"></div>
        <div class="home_hero_square-blue square_blue_6">
          <img loading="lazy" class="image_canastillas_trans" src="@/assets/image/transporte.webp" alt="">
          <h2 class="oswald_bold_white_24">Transporte</h2>
        </div>
      </div>
    </div>

    <div class="home_galery_content">
      <div class="home_galery_text">
        <h3 class="oswald_bold_blue_48 margin_bottom_20">
          Conoce nuestras grúas en acción
        </h3>
        <p class="open_regular_blue_24 margin_bottom_20">
          Ubica mediante fotografías la maniobra o transporte que necesitas
        </p>
        <div class="line_yellow width_261 margin_bottom_45"></div>
        <a class="button_generic_style button_blue-principal_blue-black height_40 width_180" href="/servicios">Ver fotos</a>
      </div>
      <div class="home_galery_image">
        <img loading="lazy" class="image_responsive" src="@/assets/image/conoce-gruas.webp" alt="">

      </div>
    </div>

    <div class="home_personal_content">

      <div class="home_personal_image">
        <img loading="lazy" class="image_responsive" src="@/assets/image/equipo-especialistas.webp" alt="">

      </div>
      <div class="home_personal_text">
        <h3 class="oswald_bold_blue_48 margin_bottom_20 text_right">
          Somos un equipo de especialistas técnicos
        </h3>
        <p class="open_regular_blue_24 margin_bottom_20 text_right">
          Siéntete en confianza de conocernos a detalle. Somos totalmente transparentes y flexibles
        </p>
        <div class="line_yellow width_261 margin_bottom_45"></div>
        <a class="button_generic_style button_blue-principal_blue-black height_40 width_180" href="/acerca-de-nosotros">Quiero
          conocerlos</a>
      </div>
    </div>

    <div class="home_ads_content">
      <div class="home_ads_top">
        <div class="home_ads_left">
          <h3 class="oswald_bold_blue_24 text_right margin_bottom_20">
            Descuento preferente para
            clientes frecuentes
          </h3>
          <p class="open_regular_blue_18 text_right">
            Tomamos en cuenta tu preferencia con precios por lealtad
          </p>
        </div>
        <div class="home_ads_right">

        </div>
      </div>
      <div class="home_ads_bottom move_y_100">
        <div class="home_ads_square_blue-strong">
          <h4 class="oswald_bold_white_24 margin_bottom_20">
            Asesoría técnica
            sin costo
          </h4>
          <p class="open_regular_white_16 margin_bottom_20">
            Nuestros técnicos siguen haciendo las mejores recomendaciones en apoyo a todos los clientes
          </p>
          <a class="button_generic_style button_yellow_orange height_40 width_100-30" href="/contratacion">Saber más</a>
        </div>
        <div class="home_ads_square_blue-strong">
          <h4 class="oswald_bold_white_24 margin_bottom_20">
            Precios del 2020 en pleno 2022
          </h4>
          <img loading="lazy" class="image_responsive margin_bottom_20" src="@/assets/image/precios-2022.webp" alt="">
          <a class="button_generic_style button_yellow_orange height_40 width_100-30" href="/contratacion">Saber más</a>
        </div>
        <div class="home_ads_rectangles">
          <div class="home_ads_rectangles_top ">
            <h4 class="oswald_bold_white_24 text_center">Contáctanos para conocer más de nuestras promociones</h4>
          </div>
          <div class="home_ads_rectangles_bottom">
            <a class="link_image" href="/contacto">
<img loading="lazy" class="image_responsive" src="@/assets/image/contactanos-conocer.webp" alt="">
            </a>
            
          </div>
        </div>
      </div>
    </div>

    <div class="home_certification_content">

      <div class="home_certification_image">
        <img loading="lazy" class="image_responsive" src="@/assets/image/empresa-certificada.webp" alt="">

      </div>
      <div class="home_certification_square">
        <div class="home_certification_text">
          <h3 class="oswald_bold_white_96 margin_bottom_30 text_right line_height_0-5">EMPRESA <br> <span
              class="oswald_bold_yellow_48">Certificada</span></h3>
          <p class="oswald_bold_white_48 text_center line_height_1-2">Responsabilidad <br>Seguridad<br>Transparencia</p>
        </div>
      </div>
    </div>

    <div class="home_info_content">
      <div class="padding_right_left_30_xs">
        <h2 class="oswald_bold_blue_48 margin_bottom_20">
          Información relevante
        </h2>
        <p class="open_regular_blue_24 margin_bottom_20">
          Aclaramos tus dudas e inquietudes a través de nuestras redacciones
        </p>
      </div>
      <div class="home_info_squares">
        <div class="home_info_square-left">
          <div class="home_info_square-left-top">
            <div class="home_info_square-left-top_text">
              <h3 class="open_regular_white_24 margin_bottom_20">¿Cómo cotizar un servicio de forma correcta?</h3>
              <a class="button_generic_style button_blue-principal_blue-black height_40 width_100-30" href="/cotizacion">Leer
                más</a>
            </div>
            <div class="home_info_square-left-top_img">
              <img loading="lazy" class="image_responsive move_x_10" src="@/assets/image/cotizar-servicio.webp" alt="">
            </div>

          </div>
          <div class="home_info_square-left-bottom">
            <div class="home_info_square-left-bottom-left">
              <h3 class="open_regular_white_24 margin_bottom_20">Anticipar o resolver en el momento</h3>
              <img loading="lazy" class="move_y_10 image_responsive" src="@/assets/image/anticipar-resolver.webp" alt="">
              <a class="button_generic_style button_blue-principal_blue-black height_40 width_100-30" href="/anticipar">Leer
                más</a>
            </div>
            <div class="home_info_square-left-bottom-right">
              <h3 class="open_regular_white_24 margin_bottom_20">¿Qué tan seguro será?</h3>
              <img loading="lazy" class="image_responsive move_y_20 " src="@/assets/image/casco-seguro.webp" alt="">
              <a class="button_generic_style button_blue-principal_blue-black height_40 width_100-30" href="seguro">Leer
                más</a>
            </div>
          </div>
        </div>

        <div class="home_info_square-right">
          <div class="home_info_square-right-top">
            <div class="home_info_square-right-top-left">
              <h3 class="open_regular_white_24 margin_bottom_20">Lista de papeles requeridos</h3>
              <img loading="lazy" class=" image_responsive mx_30" src="@/assets/image/papeles-requeridos.webp" alt="">
              <a class="button_generic_style button_blue-principal_blue-black height_40 width_100-30" href="/papeles">Leer
                más</a>
            </div>
            <div class="home_info_square-right-top-right">
              <div class="home_info_square-right-top-right-top_padding_15">
                <h3 class="open_regular_white_24 margin_bottom_20">Atención después del servicio</h3>
              </div>

              <div class="home_info_square-right-top-right-middle">
                <img loading="lazy" class="image_responsive move_y_30" src="@/assets/image/atencion-servicio.webp" alt="">
              </div>

              <div class="home_info_square-right-top-right-bottom_padding_15">
                <a class="button_generic_style button_blue-principal_blue-black height_40 width_100-30" href="/atencion">Leer
                  más</a>
              </div>

            </div>
          </div>
          <div class="home_info_square-right-bottom">
            <div class="home_info_square-right-bottom_text">
              <h3 class="open_regular_white_24 margin_bottom_20">¿Qué hacer en caso de rutas complicadas?</h3>
              <a class="button_generic_style button_blue-principal_blue-black height_40 width_100-30" href="/rutas">Leer
                más</a>
            </div>
            <div class="home_info_square-right-bottom_img">
              <img loading="lazy" class="image_responsive move_x_20" src="@/assets/image/hombre-pensando.webp" alt="">
            </div>

          </div>
        </div>
      </div>

    </div>


    <div class="home_map_content">
      <div class="home_map_style">
        <div class="home_map_info">
          <div class="home_map_img">
            
          </div>
          <div class="home_map_info_text">
            <h3 class="oswald_bold_blue_24 margin_bottom_20">Visítanos en nuestro centro de operaciones. Con gusto te recibiremos</h3>
            <p class="open_regular_blue_18 margin_bottom_30">Av. del Árbol 104 <br>
              Col. Lomas de San Lorenzo.<br>
              C.P. 09780. <br>
              Alcaldía Iztapalapa, CDMX</p>
            <a class="button_generic_style button_blue-principal_blue-black height_40 width_100-30" href="/contacto">Más información para encontrarnos</a>
          </div>

        </div>
        <div class="home_map_frame">
          <iframe class="iframe_style"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d941.2817961581807!2d-99.0558736!3d19.320286599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce028ac6695de9%3A0xc8190e836d48e1c8!2sAv.%20del%20%C3%81rbol%20104%2C%20Consejo%20Agrarista%20Mexicano%2C%20Iztapalapa%2C%2009760%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1655062884510!5m2!1ses-419!2smx"
            height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>


      </div>

    </div>
</div>



    <Footer></Footer>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    name: 'HomeView',
    components: {
      Header,
      Footer
    }
  }
</script>