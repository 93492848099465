<template>
  <div class="header">
    <div class="header_content">
      <div class="header_content_logo">              
       
        <a href="/"><img loading="lazy" class="logo-rm2-colors" src="@/assets/image/logo-rm2-colors.gif" alt=""></a>
        
      </div>
      <div class="header_content_nav">
        <div class="header_content_nav_top">
          <div class="header_content_contact">
            <a class="link_top_blue" href="tel:5517725472" target="_blank">Teléfono</a> |
            <a class="link_top_blue" href="https://telegram.me/GruasRM2" target="_blank">Telegram</a> |
            <a class="link_top_blue" href="https://wa.me/5517725472?text=hola Gruas RM2" target="_blank">Whatsapp</a> |
            <a class="link_top_blue" href="mailto:contacto@gruasytransportesrm2.com.mx" target="_blank">Email</a> |
            <a class="link_top_blue" href="https://goo.gl/maps/YGHR67wovtLYNzFY6" target="_blank">Ubicación</a>
          </div>
          <div class="header_content_social">
            <a class="link_icon " href="https://www.instagram.com/gruasytransportesrm2/"><img loading="lazy" class="icon_colors" src="@/assets/image/icon-instagram-colors.svg"
                alt=""></a>
            <a class="link_icon" href="https://www.facebook.com/profile.php?id=100063629905957"><img loading="lazy" class="icon_colors" src="@/assets/image/icon-facebook-colors.svg"
                alt=""></a>
                <!--
            <a class="link_icon" href=""><img loading="lazy" class="icon_colors" src="@/assets/image/icon-youtube-colors.svg"
                alt=""></a>
            <a class="link_icon" href=""><img loading="lazy" class="icon_colors" src="@/assets/image/icon-twitter-colors.svg"
                alt=""></a>
            <a class="link_icon" href=""><img loading="lazy" class="icon_colors" src="@/assets/image/icon-tiktok-colors.svg"
                alt=""></a>-->
          </div>
        </div>
        <div class="header_content_nav_bottom">
          
            <a class="button_generic_style button_transparent_yellow height_40" href="/">Inicio</a>
            <a class="button_generic_style button_transparent_yellow height_40" href="/acerca-de-nosotros">Acerca de nosotros</a>
            <a class="button_generic_style button_transparent_yellow height_40" href="/servicios">Servicios</a>
            <div class="header_nav_break_sm" ></div>
            <a class="button_generic_style button_transparent_yellow height_40" href="/garantias">Garantías</a>
            <a class="button_generic_style button_transparent_yellow height_40" href="/cobertura">Cobertura</a>
            <a class="button_generic_style button_transparent_yellow height_40" href="/contratacion">Contratación</a>
            <a class="button_generic_style button_yellow_orange height_40" href="/contacto">Contacto</a>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    props: {
      msg: String
    }
  }
</script>

<style scoped lang="scss">
 
</style>