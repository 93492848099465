<template>
  <div class="footer">
    <div class="footer_content">
      <div class="footer_content_logo margin_bottom_30">
        <img loading="lazy" class="logo-rm2-colors" src="@/assets/image/logo-rm2-white.svg" alt="">
      </div>
      <div class="footer_nav">

        <div class="footer_content_nav">
          <div class="footer_content_nav_list">
            <h3 class="open_bold_white_14 margin_bottom_30">Acerca de nosotros</h3>
            <a class="link_white" href="/acerca-de-nosotros">Quiénes somos</a>
            <a class="link_white" href="/acerca-de-nosotros">Proyectos</a>
            <a class="link_white" href="/acerca-de-nosotros">Certificaciones</a>
            <a class="link_white" href="/acerca-de-nosotros">Clientes</a>            
          </div>
          <div class="footer_content_nav_list">
            <h3 class="open_bold_white_14 margin_bottom_30">Servicio</h3>
            <a class="link_white" href="/servicios">Tipos de grúas</a>
            <a class="link_white" href="/servicios">Información técnica</a>
            <a class="link_white" href="/servicios">Galería</a>
            <a class="link_white" href="/servicios">Disponibilidad</a>
            <a class="link_white" href="/servicios">Precios</a>
          </div>
          <div class="footer_content_nav_list">
            <h3 class="open_bold_white_14 visibility_hidden margin_bottom_30">Servicio</h3>
            <a class="link_white" href="/cobertura">Cobertura</a>
            <a class="link_white" href="/contratacion">Contratar servicios</a>
            <a class="link_white" href="/contratacion">Documentación</a>
            <a class="link_white" href="/contratacion">Formas de pago</a>
            <a class="link_white" href="/contratacion">Promociones</a>
          </div>
          <div class="footer_content_nav_list">
            <h3 class="open_bold_white_14 margin_bottom_30">Contacto</h3>
            <a class="link_white" href="tel:5517725472" target="_blank">Teléfono</a>
            <a class="link_white" href="https://telegram.me/GruasRM2" target="_blank">Telegram</a>
            <a class="link_white" href="https://wa.me/5517725472?text=hola Gruas RM2" target="_blank">Whatsapp</a>
            <a class="link_white"  href="mailto:contacto@gruasytransportesrm2.com.mx" target="_blank">Email</a>
            <a class="link_white" href="https://goo.gl/maps/YGHR67wovtLYNzFY6" target="_blank">Ubicación</a>            
          </div>
        </div>
        <div class="footer_content_contact">
          <div class="footer_content_location margin_bottom_30">
            <div class="square_trans_left"></div>
            <div class="square_orange_center">
              <p class="open_regular_blue_14 text_left">
                Av. del Árbol 104.<br>
                Col. Lomas de San Lorenzo. <br>
                C.P. 09780. Alcaldía Iztapalapa. CDMX. México. <br><br>                
                Teléfono: <a class="link_blue" href="tel:5534508010">55 34 50 80 10</a> <br>
                Teléfono: <a class="link_blue" href="tel:5554269941">55 17 72 54 72</a> <br><br>
                Lunes a viernes de 9:00am a 6:00pm
              </p>
            </div>
            <div class="square_trans_right"></div>
          </div>
          <div class="footer_content_social">
             <a class="link_icon" href="https://www.instagram.com/gruasytransportesrm2/"><img loading="lazy" class="icon_white" src="@/assets/image/icon-instagram-white.svg" alt=""></a>
  <a class="link_icon" href="https://www.facebook.com/profile.php?id=100063629905957"><img loading="lazy" class="icon_white" src="@/assets/image/icon-facebook-white.svg" alt=""></a>
  <!--
  <a class="link_icon" href=""><img loading="lazy" class="icon_white" src="@/assets/image/icon-youtube-white.svg" alt=""></a>
  <a class="link_icon" href=""><img loading="lazy" class="icon_white" src="@/assets/image/icon-twitter-white.svg" alt=""></a>
  <a class="link_icon" href=""><img loading="lazy" class="icon_white" src="@/assets/image/icon-tiktok-white.svg" alt=""></a>-->
          </div>
        </div>
      </div>
      <div class="footer_copy">
        <p class="open_regular_white_14">
          Todos los derechos reservados 2022-23
        </p>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Footer',
    props: {
      msg: String
    }
  }
</script>

<style scoped lang="scss">

</style>